import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const DiscordIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="#9EACBA"
    >
      <path
        d="M12.11 9.5C12.11 10.11 11.66 10.61 11.09 10.61C10.53 10.61 10.07 10.11 10.07 9.5C10.07 8.89 10.52 8.39 11.09 8.39C11.66 8.39 12.11 8.89 12.11 9.5ZM7.44 8.39C6.87 8.39 6.42 8.89 6.42 9.5C6.42 10.11 6.88 10.61 7.44 10.61C8.01 10.61 8.46 10.11 8.46 9.5C8.47 8.89 8.01 8.39 7.44 8.39ZM18 2.06V20C15.4807 17.7737 16.2864 18.5106 13.36 15.79L13.89 17.64H2.55C1.42 17.64 0.5 16.72 0.5 15.58V2.06C0.5 0.92 1.42 0 2.55 0H15.95C17.08 0 18 0.92 18 2.06ZM15.15 11.54C15.15 8.32 13.71 5.71 13.71 5.71C12.27 4.63 10.9 4.66 10.9 4.66L10.76 4.82C12.46 5.34 13.25 6.09 13.25 6.09C10.8746 4.78809 8.08422 4.78785 5.78 5.8C5.41 5.97 5.19 6.09 5.19 6.09C5.19 6.09 6.02 5.3 7.82 4.78L7.72 4.66C7.72 4.66 6.35 4.63 4.91 5.71C4.91 5.71 3.47 8.32 3.47 11.54C3.47 11.54 4.31 12.99 6.52 13.06C6.52 13.06 6.89 12.61 7.19 12.23C5.92 11.85 5.44 11.05 5.44 11.05C5.58711 11.153 5.82969 11.2864 5.85 11.3C7.53789 12.2452 9.93547 12.5549 12.09 11.65C12.44 11.52 12.83 11.33 13.24 11.06C13.24 11.06 12.74 11.88 11.43 12.25C11.73 12.63 12.09 13.06 12.09 13.06C14.3 12.99 15.15 11.54 15.15 11.54Z"
        fill="inherit"
      />
    </SvgIcon>
  );
};
